import React from 'react';
import LayoutWrapper from '../components/LayoutWrapper/LayoutWrapper';
import design from '../config/design';
import { useStaticQuery, graphql } from 'gatsby';
import Title from '../components/Title/Title';
import Carousel from '../components/Carousel/Carousel';
import { View } from '../components/View/View';
import Text from '../components/Text/Text';
import ProductCardList from '../components/ProductCard/ProductCardList';
import VideoModal from '../components/VideoModal/VideoModal';
import { BigView } from '../components/BigView/BigView';

function Products() {
  const { productsInCampaign, productsConfig } = useStaticQuery(
    graphql`
      query {
        productsInCampaign: allDatoCmsProduct(
          filter: { isActiveInCampaign: { eq: true } }
        ) {
          nodes {
            productName
            productImage {
              alt
              url
            }
            shortDescription
            isActiveInCampaign
            campaignVideoId
            productUrl
          }
        }
        productsConfig: allDatoCmsProductConfig {
          edges {
            node {
              subtitleSectionCampaign
              subtitleSectionNationalProducts
              subtitleSectionOtherProducts
              titleSectionCampaign
              titleSectionNationalProducts
              titleSectionOtherProducts
              # productBannerCampaign {
              #   bannerImage {
              #     url
              #   }
              #   linkCampaignUrl
              #   textImage
              # }
            }
          }
        }
      }
    `
  );

  const contentText = productsConfig.edges[0].node;

  return (
    <>
      <LayoutWrapper>
        <BigView>
          <View
            desktopLeft={6}
            desktopRight={6}
            mobileLeft={1}
            mobileRight={1}
            tabletLeft={3}
            tabletRight={3}
            bottom={2}
          >
            {/* <View top={3.5} bottom={3.5}>
            <Carousel content={contentText.productBannerCampaign} />
          </View> */}
            <View top={3} />
            <Title
              color={design.colors.blue150}
              content={contentText.titleSectionCampaign}
            />
            <Text
              color={design.colors.dark}
              content={contentText.subtitleSectionCampaign}
            />
            <View top={2} />
            <ProductCardList items={productsInCampaign.nodes} />
          </View>
        </BigView>
      </LayoutWrapper>
    </>
  );
}

export default Products;
